import React, { useState, useEffect, useRef } from "react";
import { Image } from 'primereact/image';
import controlAduanas from '../../../../images/control_aduanas.png';
import desarrollo from '../../../../images/desarrollo_medida.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel } from 'primereact/carousel';

function Productos(){

    const productos = [
        {
            id: 1,
            titulo: 'Control Aduanas',
            descripcion: 'Nuestro sistema de gestión logística aduanera está diseñado para optimizar y simplificar los procesos operativos de las empresas dedicadas a la logística de aduanas en Costa Rica. Este sistema integral permite la administración eficiente de todas las fases del proceso aduanero, asegurando la transparencia, precisión y agilidad en cada operación.',
            imagen: controlAduanas,
            caracteristicas: [
                {
                    id: 1,
                    descripcion: 'Manejo de Consecutivos de Aduana'
                },
                {
                    id: 2,
                    descripcion: 'Solicitud de Pagos a Terceros, impuestos, etc'
                },
                {
                    id: 3,
                    descripcion: 'Gestión de Procesos Aduaneros (Permisos, inspección, Borrador, Transmisión etc)'
                },
                {
                    id: 4,
                    descripcion: 'Liquidación de Gastos'
                }
            ],
            beneficios: [
                {
                    id: 1,
                    descripcion: 'Eficiencia Operativa: Reducción de tiempos de procesamiento y minimización de errores humanos a través de la automatización de tareas clave.'
                },
                {
                    id: 2,
                    descripcion: 'Transparencia y Control: Seguimiento detallado de cada operación, proporcionando visibilidad completa sobre el estado de los procesos y la gestión financiera.'
                }
            ]
        },
        {
            id: 2,
            titulo: 'Desarrollo a la Medida',
            descripcion: 'En Synergy Methods Development Solutions Costa Rica, nos dedicamos a brindar soluciones de desarrollo de software a medida, posicionándonos como el mejor aliado estratégico para la transformación digital de tu negocio. Con un enfoque centrado en el cliente y una pasión por la innovación, ofrecemos una gama de servicios que abarca desde el desarrollo de aplicaciones web hasta la implementación de sistemas empresariales complejos.',
            imagen: desarrollo,
            caracteristicas: [
                {
                    id: 1,
                    descripcion: 'Desarrollo de Software a Medida'
                },
                {
                    id: 2,
                    descripcion: 'Transformación Digital'
                },
                {
                    id: 3,
                    descripcion: 'Soporte y Mantenimiento'
                },
            ],
            beneficios: [
                {
                    id: 1,
                    descripcion: 'Experiencia y Conocimiento: Contamos con un equipo de profesionales altamente capacitados con amplia experiencia en el desarrollo de software y transformación digital.'
                },
                {
                    id: 2,
                    descripcion: 'Enfoque en el Cliente: Nuestro compromiso es entender tus necesidades y objetivos, ofreciendo soluciones que realmente impulsen tu negocio.'
                },
                {
                    id: 3,
                    descripcion: 'Innovación Constante: Nos mantenemos a la vanguardia de la tecnología para ofrecerte siempre las mejores y más modernas soluciones.'
                },
                {
                    id: 4,
                    descripcion: 'Resultados Tangibles: Nos enfocamos en entregar resultados que marquen una diferencia significativa en la operación y crecimiento de tu empresa.'
                }
            ],
        },
    ];

    const productTemplate = (producto) => {
        return (
            <div className="flex justify-content-center wrap gap-2" style={{backgroundColor: '#001233'}}>
                <div className="mt-8 w-full md:w-6 text-color flex justify-content-end wrap">
                    <div className="w-10">
                        <h1>{producto.titulo}</h1>
                        <div className="w-8 mt-2 ml-5">
                            <span>{producto.descripcion}</span>
                        </div>
                        {producto.caracteristicas?<br />:<></>}
                        {producto.caracteristicas?<h2>Características Principales:</h2>:<></>}
                        {producto.caracteristicas?<div className="w-8 mt-2 ml-5">
                            {producto.caracteristicas?producto.caracteristicas.map((caracteristica) => {
                                return <ul>
                                        <li key={caracteristica.id}>
                                            {caracteristica.descripcion}
                                        </li>
                                       </ul>;
                            }):<></>}
                        </div>:<></>}
                        {producto.beneficios?<br />:<></>}
                        {producto.beneficios?<h2>Beneficios del Sistema:</h2>:<></>}
                        {producto.beneficios?<div className="w-8 mt-2 ml-5">
                            {producto.beneficios?producto.beneficios.map((beneficio) => {
                                return <ul>
                                        <li key={beneficio.id}>
                                            {beneficio.descripcion}
                                        </li>
                                       </ul>;
                            }):<></>}
                        </div>:<></>}
                        <br />
                        <span>Contactanos y solicita tu demo</span>
                    </div>
                </div>
                <div className="w-6 flex justify-content-center wrap" >
                    <img src={producto.imagen} alt="Image" width="80%"/>
                </div>
            </div>
        );
    };

    return(
        <React.Fragment>
            <div className="mt-7 flex flex-wrap" style={{minHeight: window.innerHeight}}>
                <Carousel value={productos} numVisible={1} numScroll={1} circular itemTemplate={productTemplate} autoplayInterval={3000} activeIndex={0}/>
            </div>
        </React.Fragment>
    );
}

export default Productos;