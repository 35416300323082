import React from "react";
import Top from "./Top";
import Body from "./Body";

function Layout(props){
    return(
        <React.Fragment>
            <section className="w-full flex flex-wrap" style={{backgroundColor: '#001233'}}>
                <Top />
                <Body />
            </section>
        </React.Fragment>
    );
}

export default Layout;