import React from "react";
import photo1 from '../../../../images/webDevelopment.png';
import curve from '../../../../images/curve3.png';
import textura from '../../../../images/textura.png';

function Nosotros(){
    const Mision = "Empoderar a las pequeñas y medianas empresas a través de soluciones tecnológicas innovadoras y accesibles, facilitando su transformación digital y permitiéndoles competir en un mercado global. Nos comprometemos a ofrecer herramientas y servicios personalizados que optimicen sus operaciones, mejoren su eficiencia y fomenten su crecimiento sostenible.";
    const Vision = "Ser el socio líder en la digitalización de pequeñas y medianas empresas, reconocido por nuestra capacidad de ofrecer soluciones tecnológicas de vanguardia que transformen la manera en que las empresas operan y crecen. Aspiramos a crear un ecosistema empresarial donde todas las pequeñas empresas tengan acceso a la tecnología necesaria para prosperar en la era digital.";

    return(
        <React.Fragment>
            <div style={{scrollBehavior: "smooth"}}>
                <div className="fadein animation-duration-1000 animation-iteration-1 w-full flex flex-wrap">
                    <div className="w-full flex flex-wrap">
                        <div className="m-8 flex justify-content-center flex-wrap gap-2">
                            <div className="w-6 text-color text-justify flex justify-content-end flex-wrap" style={{marginTop: "160px"}}>
                                <div className="w-10">
                                    <div className="w-8 mt-2 ml-5">
                                        <h2> Quienes somos</h2>
                                        <div className="w-8 mt-2 ml-5">
                                            <span>Somos una compañía costarricense especializada en brindar soluciones tecnológicas de vanguardia para pequeñas y medianas empresas. Con una profunda comprensión de las necesidades y desafíos que enfrentan las pequeñas y medianas empresas en el entorno digital actual. Ofrecemos servicios de desarrollo de software a la medida y productos propios diseñados para impulsar su transformación digital y competitividad.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-6 flex justify-content-center flex-wrap" style={{marginTop: "80px", marginLeft: "-20px"}}>
                                <img src={photo1} alt="Image" width="80%"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex wrap" style={{marginTop: "-120px"}}>
                    <div className="w-full flex wrap">
                        <div className="m-8 flex justify-content-center wrap gap-2">
                            <div className="fadeinleft animation-duration-1000 animation-iteration-1 mt-8 w-6 text-color text-justify flex justify-content-end flex-wrap">
                                <div className="w-10">
                                    <div className="w-8 mt-2" style={{marginLeft: "120px"}}>
                                        <h2> Misión </h2>
                                        <div className="w-8 mt-2 ml-5">
                                            <span>{Mision}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fadeinright animation-duration-1000 animation-iteration-1 mt-8 w-6 text-color text-justify flex justify-content-end flex-wrap">
                                <div className="w-10">
                                    <div className="w-8 mt-2 ml-5">
                                        <h2> Visión </h2>
                                        <div className="w-8 mt-2 ml-5">
                                            <span>{Vision}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full" style={{marginTop: "-220px"}}>
                    <img src={curve} alt="Image" width="100%" height="800px"/>
                </div>
                <div className="w-full">
                    <div className="w-full flex justify-content-center wrap text-color text-justify">
                        <h1>Valores</h1>
                    </div>
                    <div className="mt-5 w-full flex justify-content-center flex-wrap text-color gap-5" style={{paddingLeft: "200px"}}>
                        <div className="w-3 flex flex-wrap">
                            <div className="w-full">
                                <h2>Innovación</h2>
                            </div>
                            <div className="w-8">
                                <span>Fomentamos la creatividad y la búsqueda continua de soluciones tecnológicas avanzadas que impulsen la transformación digital de las pequeñas empresas.</span>
                            </div>
                        </div>
                        <div className="w-3 flex flex-wrap">
                            <h2>Compromiso</h2>
                            <div className="w-8">
                                <span>Estamos dedicados a ofrecer un servicio de alta calidad y a mantener relaciones sólidas y duraderas con nuestros clientes, basadas en la confianza y la colaboración.</span>
                            </div>
                        </div>
                        <div className="w-3 flex flex-wrap">
                            <h2>Accesibilidad</h2>
                            <div className="w-8">
                                <span>Creemos que todas las pequeñas empresas, independientemente de su tamaño o presupuesto, merecen acceso a tecnologías de vanguardia que mejoren sus operaciones y competitividad.</span>
                            </div>
                        </div>
                        <div className="w-3">
                            <h2>Excelencia</h2>
                            <div className="w-8">
                                <span>Nos esforzamos por superar las expectativas en cada proyecto, proporcionando soluciones que no solo satisfagan, sino que superen las necesidades de nuestros clientes.</span>
                            </div>
                        </div>
                        <div className="w-3">
                            <h2>Integridad</h2>
                            <div className="w-8">
                                <span>Actuamos con honestidad y transparencia en todas nuestras interacciones, asegurando que nuestras acciones y decisiones reflejen nuestros principios éticos.</span>
                            </div>
                        </div>
                        <div className="w-3">
                            <h2>Orientación al Cliente</h2>
                            <div className="w-8">
                                <span>Ponemos a nuestros clientes en el centro de todo lo que hacemos, diseñando soluciones personalizadas que se ajusten a sus necesidades y objetivos específicos.</span>
                            </div>
                        </div>
                        <div className="w-3">
                            <h2>Sostenibilidad</h2>
                            <div className="w-8">
                                <span>Promovemos prácticas empresariales sostenibles que no solo beneficien a nuestros clientes y empleados, sino también al medio ambiente y a la comunidad en general.</span>
                            </div>
                        </div>
                        <div className="w-3">
                            <h2>Colaboración</h2>
                            <div className="w-8">
                                <span>Creemos en el poder del trabajo en equipo y fomentamos una cultura de colaboración tanto interna como con nuestros clientes y socios.</span>
                            </div>
                        </div>
                        <div className="w-3">
                            <h2> Educación Continua</h2>
                            <div className="w-8">
                                <span>Nos comprometemos a mantenernos al día con los avances tecnológicos y a compartir este conocimiento con nuestros clientes para que puedan maximizar el valor de nuestras soluciones.</span>
                            </div>
                        </div>
                        <div className="w-3">
                            <h2> Responsabilidad Social</h2>
                            <div className="w-8">
                                <span>Reconocemos nuestra responsabilidad hacia la sociedad y nos esforzamos por contribuir positivamente a las comunidades donde operamos.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full" style={{marginTop: "-720px", opacity: "20%"}}>
                    <img src={textura} alt="Image" width="100%" height="1000px"/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Nosotros;