import React from "react";
import photo1 from '../../../../images/development_inicio.png';
import mapacr from '../../../../images/mapa_costa_rica.png';

function Inicio(){

    const Lema = "Para pequeñas y medianas empresas, con una profunda comprensión de las necesidades y desafíos que enfrentan";
    //console.log('me llamaron');

    return(
        <React.Fragment>
            <div style={{minHeight: window.innerHeight, scrollBehavior: "smooth"}}>
                <div className="w-full flex wrap scalein animation-duration-1000 animation-iteration-1">
                    <div className="w-full flex wrap">
                        <div className="m-8 flex justify-content-center wrap gap-2">
                            <div className="mt-8 w-6 text-color flex justify-content-end wrap">
                                <div className="w-10">
                                    <div className="w-12 mt-4 ml-5 flex flex-wrap">
                                        <div className="w-full text-5xl font-bold">Synergy Methods Development Solutions Costa Rica</div>
                                        <div className="w-10 mt-2 ml-6 text-xl text-justify">
                                            <span>Soluciones tecnologicas de vanguardia para pequeñas y medianas empresas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-6 flex justify-content-center wrap" >
                                <img src={photo1} alt="Image" width="80%"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex wrap bg-primary">
                    <div className="w-full flex wrap">
                        <div className="m-8 flex justify-content-center wrap gap-2">
                            <div className="mt-8 w-6 text-gray-800 text-justify flex justify-content-end wrap">
                                <div className="w-10">
                                    <div className="w-8 mt-2 ml-5">
                                        <div className="w-10 mt-2 ml-5 text-xl">
                                            <span>Ubicados en Costa Rica, nuestro equipo de expertos está dedicado a diseñar y desarrollar soluciones personalizadas que se adapten a las necesidades específicas de cada cliente. Ya sea a través de servicios de desarrollo de software a la medida o de nuestros productos propios, en Synergy Mmethods Developmensts Solutions estamos comprometidos a acompañar a las pequeñas y medianas empresas en su camino hacia la digitalización, asegurando su éxito y crecimiento sostenible en el competitivo mundo actual.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-6 flex justify-content-center wrap" >
                                <img src={mapacr} alt="Image" width="80%"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Inicio;