import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

function Body(props){
    return(
        <React.Fragment>
            <div className="layout-main-container w-full mt-8" style={{minHeight: window.innerHeight}}>
                <Outlet />
                <Footer />
            </div>
        </React.Fragment>
    );
}

export default Body;