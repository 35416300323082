import React from "react";
import logo from '../../../../images/smds_blanco_humo.png';
import { Toolbar } from 'primereact/toolbar';
import { MegaMenu } from 'primereact/megamenu';

function Top(props){

    const items = [
        {
            label: 'Inicio',
            icon: 'pi pi-home',
            url: '/'
        },
        {
            label: 'Productos',
            icon: 'pi pi-shopping-bag',
            url: 'productos'
        },
        {
            label: 'Nosotros',
            icon: 'pi pi-users',
            url: 'nosotros'
        },
        {
            label: 'Contactenos',
            icon: 'pi pi-comments',
            url: 'contactenos'
        }
    ];

    function start(){
        return(
            <React.Fragment>
                <div className="w-full flex flex-wrap">
                    <div className="w-3">
                        <img className="w-12" src={logo} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function center(){

    }

    function end(){
        return(
            <React.Fragment>
                <div className="w-full flex flex-wrap">
                    <MegaMenu model={items} orientation="horizontal" className="mt-1 border-0"/>
                </div>
            </React.Fragment>
        );
    }

    return(
        <React.Fragment>
            <div className="w-full" style={{backgroundColor: '#001233', position: "fixed", zIndex: '2'}}>
                <Toolbar className="w-full border-0 shadow-5" start={start} end={end}/>
            </div>
        </React.Fragment>
    );
}

export default Top;