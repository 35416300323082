import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Layout from "./components/layout/Layout";
import Inicio from './components/pages/Inicio';
import Productos from './components/pages/Productos';
import Nosotros from './components/pages/Nosotros';
import Contactenos from './components/pages/Contactenos';
import NotFoundPage from './components/pages/NotFoundPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <NotFoundPage />,
        children: [
          {
            index: true,
            path: "/",
            element: <Inicio />,
          },
          {
            path: "/productos",
            element: <Productos />,
          },
          {
            path: "/nosotros",
            element: <Nosotros />,
          },
          {
            path: "/contactenos",
            element: <Contactenos />,
          },
        ],
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
  ]);

function Routing(){
    return(
        <RouterProvider router={router} />
    );
}

export default Routing;