import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationDot, faX} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn, } from '@fortawesome/free-brands-svg-icons';
import logo from '../../../../images/smds_blanco_humo.png';

function Footer(props){

    return(
        <React.Fragment>
            <section className="w-full" style={{backgroundColor: '#001233'}}>
                <div className="w-full mt-4 pb-4 flex justify-content-center text-color flex-wrap">
                    <div className="w-8 flex align-content-center justify-content-between flex-wrap">
                        <div className="w-4 mt-2 flex justify-content-between flex-wrap">
                            <img className="w-8 h-8rem" src={logo} style={{marginLeft: "25%"}}/>
                        </div>
                        <div className="w-4 flex flex-wrap">
                            <div style={{marginLeft: "30%"}}>
                                <div className="w-full flex flex-wrap">
                                    <h2>Contacto</h2>
                                </div>
                                <div className="mt-2 w-full flex flex-wrap">
                                    <div>
                                        <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
                                        <a className="text-color" href="mailto:ventas@smdscr.com" style={{textDecoration: "none"}}>ventas@smdscr.com</a>
                                    </div>
                                </div>
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div>
                                        <FontAwesomeIcon className="mr-2" icon={faPhone} />
                                        <span>+506 85673940</span>
                                    </div>
                                </div>
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="flex flex-wrap">
                                        <FontAwesomeIcon className="mt-1 ml-1 mr-2" icon={faLocationDot} />
                                        <div className="w-9 flex flex-wrap">
                                            Urbanización Villas de Florencia Santiago del Monte, San Diego, La Unión, Cartago, Costa Rica.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-4 flex flex-wrap">
                            <div style={{marginLeft: "15%"}}>
                                <div>
                                    <h2>Síganos</h2>
                                </div>
                                <div className="text-2xl flex align-items-center flex-wrap gap-2">
                                    <a className="text-color" href="https://www.facebook.com/profile.php?id=61560660416848"><FontAwesomeIcon className="mt-1 ml-1 mr-2" icon={faFacebookF} /></a>
                                    <a className="text-color" href="https://www.facebook.com/profile.php?id=61560660416848"><FontAwesomeIcon className="mt-1 ml-1 mr-2" icon={faX} /></a>
                                    <a className="text-3xl text-color" href="https://www.facebook.com/profile.php?id=61560660416848"><FontAwesomeIcon className="mt-1 ml-1 mr-2" icon={faInstagram} /></a>
                                    <a className="text-3xl text-color" href="https://www.facebook.com/profile.php?id=61560660416848"><FontAwesomeIcon className="mt-1 ml-1 mr-2" icon={faLinkedinIn} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 mb-4 flex justify-content-center flex-wrap">
                    <div className="text-color">© 2024 Develop by SM Development Solutions, All rights reserved</div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Footer;