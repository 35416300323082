import React, { useState} from "react";
import photo1 from '../../../../images/html-css-collage-concept-with-person.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFileLines, faHashtag, faIdCard, faPager} from '@fortawesome/free-solid-svg-icons';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Editor } from "primereact/editor";
import { sendEmail } from "../utilities/email";

function Productos(){
    const [asunto, setAsunto] = useState("");
    const [nombre, setNombre] = useState("");
    const [correo, setCorreo] = useState("");
    const [telefono, setTelefono] = useState("");
    const [mensaje, setMensaje] = useState("");

    function handleAsuntoInput(e){
        setAsunto(e.target.value);
    }

    function handleNombreInput(e){
        setNombre(e.target.value);
    }

    function handleCorreoInput(e){
        setCorreo(e.target.value);
    }

    function handleTelefonoInput(e){
        setTelefono(e.target.value);
    }

    function handleMensajeInput(e){
        setMensaje(e.html);
    }

    function send(e){
        e.preventDefault();
        sendEmail(e.target);
    }

    return(
        <React.Fragment>
            <div className="" style={{minHeight: window.innerHeight}}>
                <div className="w-full flex justify-content-center wrap" style={{position: 'relative', top: '0', left: '0', zIndex: '1'}}>
                    <div className="flex justify-content-center wrap gap-2">
                        <div className="mt-8 w-full text-color flex justify-content-end wrap">
                            <div className="w-10">
                                <form onSubmit={send}>
                                    <h1>Formulario de Contacto</h1>
                                    <div className="p-inputgroup flex-1">
                                        <span className="p-inputgroup-addon">
                                            <FontAwesomeIcon icon={faIdCard} />
                                        </span>
                                        <InputText placeholder="Nombre Completo" name="from_name" value={nombre} onChange={(e) => handleNombreInput(e)}/>
                                    </div>
                                    <br />
                                    <div className="p-inputgroup flex-1">
                                        <span className="p-inputgroup-addon">
                                            <FontAwesomeIcon icon={faPager} />
                                        </span>
                                        <InputText placeholder="Asunto" name="subject" value={asunto} onChange={(e) => handleAsuntoInput(e)}/>
                                    </div>
                                    <br />
                                    <div className="p-inputgroup flex-1">
                                        <span className="p-inputgroup-addon">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </span>
                                        <InputText placeholder="Correo" name="email_from" value={correo} onChange={(e) => handleCorreoInput(e)}/>
                                    </div>
                                    <br />
                                    <div className="p-inputgroup flex-1">
                                        <span className="p-inputgroup-addon">
                                            <FontAwesomeIcon icon={faHashtag} />
                                        </span>
                                        <InputText placeholder="Teléfono" name="phone" value={telefono} onChange={(e) => handleTelefonoInput(e)}/>
                                    </div>
                                    <br />
                                    <div className="p-inputgroup flex-1">
                                        <span className="p-inputgroup-addon">
                                            <FontAwesomeIcon icon={faFileLines} />
                                        </span>
                                        <Editor className="w-full" value={mensaje} name="message" onTextChange={(e) => handleMensajeInput(e)} style={{ height: '320px' }} />
                                    </div>
                                    <br />
                                    <Button type="submit" label={'Enviar'} className="w-10rem mr-1 p-2 text-md align-items-center" ></Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center wrap" style={{position: 'absolute', top: '0', left: '0', width: '100%', zIndex: '0', opacity: '20%'}}>
                    <img src={photo1} alt="Image" width="100%" height="1000px"/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Productos;